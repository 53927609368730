export const colors = {
  primaryBlue: '#4B7EF7',
  primary: '#4B7EF7',
  primaryBlue50: '#4B7EF799',
  primaryBlue25: '#4B7EF72b',
  primaryBlue05: '#4b7ef70d',
  // green: '#57ff87', Original Green
  // green: '#3eb489', Brandons Green
  green: '#18cc8a',
  black: '#36454f',
  gold: '#DAC480',
  lightGrey: '#d6d6d6',
  errorText: '#d32f2f',
  regularBackground: '#F0F5F8',
}
