import { useContext, useEffect, useState } from 'react'
import { IonContent, IonPage, IonLabel, IonImg, IonCol, IonRow, IonCard, IonIcon, IonGrid, IonRouterLink } from '@ionic/react'
import Card from '../commonComponents/card'
import { AuthContext } from '../auth'
import { logoFacebook, logoInstagram, mailOpenOutline, storefrontOutline } from 'ionicons/icons'
import { colors } from '../theme/colors'
import StandardContainer from '../commonComponents/standardContainer'
import { isLocationOpen } from '../utils'
import { StatusBar, Style } from '@capacitor/status-bar'
import { useLocation } from 'react-router-dom'
import StandardCenterCenter from '../commonComponents/StandardCenterContainer'
import ServicesList from './servicesList'
import Testimonials from './Testimonials'
import Gallery from './Gallery'
import { Helmet } from 'react-helmet'
import Map from './location/map'
import axios from 'axios'

const AdsLandingPage = ({ openLoginModal, openRegisterModal, openScheduleModal, openWalkinModal, appointments, locations, getAppointments }) => {
  const { userData, businessId } = useContext(AuthContext)
  const [locationData, setLocationData] = useState(null)
  const [locationError, setLocationError] = useState(false)
  const location = useLocation()

  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  let isSmall = vw > 500 ? false : true

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const locationId = searchParams.get('id')
    if (locationId) {
      fetchLocationData(locationId)
    }
  }, [location])

  const fetchLocationData = async (locationId) => {
    try {
      setLocationError(false)
      const response = await axios.get(`/location_v2/publicGet?locationId=${locationId}`)
      setLocationData(response.data)
    } catch (error) {
      console.error('Error fetching location data:', error)
      setLocationError(true)
    }
  }

  const setStatusBarStyleLight = async () => {
    try {
      await StatusBar.setStyle({ style: Style.Light })
    } catch (error) {}
  }

  const setStatusBarStyleDark = async () => {
    try {
      await StatusBar.setStyle({ style: Style.Dark })
    } catch (error) {}
  }

  useEffect(() => {
    setStatusBarStyleDark()
  }, [])

  const windowLocation = useLocation()

  useEffect(() => {
    if (windowLocation.pathname === '/home' || windowLocation.pathname === '/') {
      setStatusBarStyleDark()
    } else {
      setStatusBarStyleLight()
    }

    return setStatusBarStyleLight
  }, [windowLocation])

  return (
    <IonPage>
      <Helmet>
        <title>Unlimited Haircuts</title>
      </Helmet>
      {/* <IonHeader>
        <IonToolbar color="white" style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
          <IonTitle class="ion-text-center">
            <img src='assets/logoLeftBlack.png' height={120} style={{ marginTop: -45, marginBottom: -50 }} />
          </IonTitle>
        </IonToolbar>
      </IonHeader> */}
      <IonContent fullscreen={true}>
        <HeroSectionCard2 isSubscribed={userData?.isSubscribed} userData={userData} openWalkinModal={openWalkinModal} locations={locations} />
        <OurStory />
        <UnlimitedCard isSubscribed={userData?.isSubscribed} userData={userData} openWalkinModal={openWalkinModal} locations={locations} />
        <Testimonials />
        {locationData ? (
          <div style={{ backgroundColor: 'black' }}>
            <ServicesList businessId={businessId} locationId={locationData._id} />
          </div>
        ) : (
          null
        )}
        <Gallery />
        {locationData && <LocationSection locationData={locationData} />}
        <OtherLocations currentLocationId={locationData?._id} locations={locations} />
        <Footer />
      </IonContent>
    </IonPage>
  )
}

function OurStory() {
  return (
    <div style={{ backgroundColor: 'black', color: 'white' }}>
      <StandardContainer>
        <p style={{ fontFamily: 'Reenie Beanie', color: colors.green, fontSize: 30, marginTop: 40, marginBottom: 0 }}>Our Difference</p>
        <p style={{ fontFamily: 'Montserrat, Arial', fontWeight: '500', fontSize: 16, marginBottom: 30 }}>
          Most of us don't like shelling out cash for a haircut, which leads to a few weeks of overgrown hair. ClubCuts aims to solve this by offering unlimited
          hair cuts, when you want them; all at a competitive price. So now you can look good all the time without sacrificing your wallet. You dont need a
          subscription to get a single servce either. Once you see the value, you'll never go back.{' '}
        </p>
      </StandardContainer>
    </div>
  )
}

function HeroSectionCard2({ userData, openWalkinModal, locations }) {
  let height = 500

  let leftMargin = 30

  let locationOpenText = isLocationOpen(locations?.[0])

  return (
    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', marginBottom: -50, backgroundColor: 'black' }}>
      <IonImg style={{ width: '100%', height: height, objectFit: 'cover', marginTop: -1 }} src={'/assets/hero3.png'}></IonImg>
      <StandardContainer padding={0}>
        <div
          style={{
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'absolute',
            zIndex: 99,
            top: 0,
            color: '#fff',
            marginTop: 0,
            width: '100%',
            maxWidth: 800,
          }}
        >
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <div
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: '100%', maxWidth: 800 }}
            >
              <img src='assets/logos/CLUBCUTS_TEXT_RIGHT_WHITE.png' height={50} style={{}} />
            </div>
            <span style={{ fontFamily: 'Reenie Beanie', color: colors.green, fontSize: 30, marginTop: 10, textShadow: '1px 1px 10px #111', fontWeight: '600' }}>
              #staycut
            </span>
            <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 50, marginBottom: -14, textShadow: '1px 1px 10px black' }}>THE</h1>
            <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 50, marginBottom: -14, textShadow: '1px 1px 10px black' }}>HAIRCUT</h1>
            <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 50, marginBottom: -14, textShadow: '1px 1px 10px black' }}>YOU'VE</h1>
            <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 50, marginBottom: -14, textShadow: '1px 1px 10px black' }}>ALWAYS</h1>
            <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 50, marginBottom: 0 }}>
              WANTED<span style={{ color: colors.green }}>.</span>
            </h1>
            <p style={{ fontFamily: 'Montserrat, Arial', marginBottom: -20, fontWeight: '500', fontSize: 16 }}>Walk-ins welcome | Appointments encouraged</p>
            <br></br>
            <br></br>
          </div>
          <div style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 10 }}>
            <IonRouterLink routerLink='/schedule' routerDirection='none'>
              <div
                style={{
                  fontFamily: 'Montserrat, Arial',
                  padding: 10,
                  fontWeight: '600',
                  fontSize: 16,
                  color: 'black',
                  backgroundColor: colors.green,
                  border: `1px solid ${colors.green}`,
                  borderRadius: 6,
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                Book Now
              </div>
            </IonRouterLink>
          </div>
        </div>
      </StandardContainer>
    </div>
  )
}

function UnlimitedCard({ isSmall, isSubscribed, userData, openWalkinModal, locations }) {
  let height = 600
  if (!userData || userData == 'loading') {
    height = 550
  }

  let leftMargin = 30

  let locationOpenText = isLocationOpen(locations?.[0])

  return (
    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', backgroundColor: 'black', width: '100%' }}>
      <IonImg style={{ width: '100%', height: height, objectFit: 'cover', marginTop: -1 }} src={'/assets/blackBackground.png'}></IonImg>
      <StandardContainer padding={0}>
        <div
          style={{
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'absolute',
            zIndex: 99,
            top: 0,
            color: '#fff',
            marginTop: 0,
            width: '100%',
            maxWidth: 800,
          }}
        >
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <div
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 0, width: '100%', maxWidth: 800 }}
            >
              <img src='assets/infinitySignGold.png' height={230} style={{ marginTop: -50, marginBottom: -20 }} />
            </div>
            <span style={{ fontFamily: 'Reenie Beanie', color: colors.gold, fontSize: 30, marginTop: 10, textShadow: '1px 1px 10px #444' }}>Membership</span>
            <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 54, marginBottom: -14, textShadow: '1px 1px 10px black' }}>UNLIMITED</h1>
            <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 54, marginBottom: -14, textShadow: '1px 1px 10px black' }}>
              HAIRCUTS<span style={{ color: colors.gold }}>.</span>
            </h1>
            <p style={{ fontFamily: 'Montserrat, Arial', marginBottom: 0, fontWeight: '400', fontSize: 26 }}>
              Starting at <span style={{ fontWeight: '700', color: colors.gold }}>$59</span>
              <span style={{ fontSize: 16 }}>/mo</span>{' '}
            </p>
            <p style={{ fontFamily: 'Montserrat, Arial', marginBottom: -20, fontWeight: '500', fontSize: 16 }}>
              For those who want to stay looking good all the time.
            </p>
            <br></br>
          </div>
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <IonRouterLink routerLink='/subscription' routerDirection='none'>
              <div
                style={{
                  fontFamily: 'Montserrat, Arial',
                  padding: 10,
                  fontWeight: '600',
                  fontSize: 16,
                  color: 'black',
                  backgroundColor: colors.gold,
                  border: `1px solid ${colors.gold}`,
                  borderRadius: 6,
                  textAlign: 'center',
                  marginTop: 20,
                }}
              >
                Unlimited Packages
              </div>
            </IonRouterLink>
          </div>
        </div>
      </StandardContainer>
    </div>
  )
}

function LocationSection({ locationData }) {
  if (!locationData) return null;

  return (
    <div style={{ backgroundColor: 'black', color: 'white', paddingBottom: 40 }}>
      <StandardContainer>
        <p style={{ fontFamily: 'Reenie Beanie', color: colors.green, fontSize: 30, marginTop: 40, marginBottom: 0 }}>Our Location</p>
        <h1 style={{ marginTop: 0, fontWeight: '800', fontSize: 50, marginBottom: 20, textShadow: '1px 1px 10px black' }}>
          {locationData.name}<span style={{ color: colors.green }}>.</span>
        </h1>
        
        {locationData.coverPhotoURL && (
          <IonImg 
            style={{ 
              width: '100%', 
              height: 300, 
              objectFit: 'cover',
              borderRadius: 12,
              marginBottom: 20
            }} 
            src={locationData.coverPhotoURL} 
          />
        )}

        <div style={{ marginBottom: 20 }}>
          <p style={{ margin: 0 }}>{locationData.addressStreet1}</p>
          {locationData.addressStreet2 && <p style={{ margin: 0 }}>{locationData.addressStreet2}</p>}
          <p style={{ margin: 0 }}>
            {locationData.addressCity}, {locationData.addressState} {locationData.addressZipcode}
          </p>
          <p style={{ margin: 0 }}>{locationData.phoneNumber}</p>
        </div>

        {locationData.latitude && locationData.longitude && (
          <div style={{ height: 250, borderRadius: 12, marginBottom: 40, width: '100%', overflow: 'hidden' }}>
            <Map latitude={locationData.latitude} longitude={locationData.longitude} />
          </div>
        )}

        <div style={{ marginTop: 20 }}>
          <h2 style={{ fontSize: 24, marginBottom: 10 }}>Hours of Operation</h2>
          <p style={{ marginBottom: 20 }}>{isLocationOpen(locationData)}</p>
          
          {locationData.standardSchedule && (
            <IonGrid style={{ padding: 0 }}>
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                <IonRow key={day}>
                  <IonCol size="6" style={{ fontWeight: '600' }}>{day}</IonCol>
                  <IonCol size="6">
                    {locationData.standardSchedule[day]?.enabled 
                      ? `${locationData.standardSchedule[day]?.startTime} - ${locationData.standardSchedule[day]?.endTime}`
                      : 'Closed'
                    }
                  </IonCol>
                </IonRow>
              ))}
            </IonGrid>
          )}
        </div>
      </StandardContainer>
    </div>
  );
}

function OtherLocations({ currentLocationId, locations }) {
  if (!locations || locations.length <= 1) return null;

  const otherLocations = locations.filter(location => location._id !== currentLocationId);
  
  if (otherLocations.length === 0) return null;

  return (
    <div style={{ backgroundColor: 'black', color: 'white', paddingBottom: 40 }}>
      <StandardContainer>
        <p style={{ fontFamily: 'Reenie Beanie', color: colors.gold, fontSize: 30, marginTop: 0, marginBottom: 0 }}>{currentLocationId ? 'Other Locations' : 'Locations'}</p>
        <h2 style={{ marginTop: 0, fontWeight: '800', fontSize: 30, marginBottom: 20 }}>
        {currentLocationId ? 'Visit Us Elsewhere' : 'Visit Us at Multiple Locations'}<span style={{ color: colors.gold }}>.</span>
        </h2>
        
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))', gap: '20px' }}>
          {otherLocations.map(location => (
            <IonCard key={location._id} style={{ margin: 0, backgroundColor: '#111', color: 'white' }}>
              {location.coverPhotoURL && (
                <IonImg 
                  style={{ 
                    width: '100%', 
                    height: 150, 
                    objectFit: 'cover'
                  }} 
                  src={location.coverPhotoURL} 
                />
              )}
              <div style={{ padding: 15 }}>
                <h3 style={{ margin: 0, marginBottom: 10, color: colors.gold }}>{location.name}</h3>
                <p style={{ margin: 0, fontSize: 14, marginBottom: 5 }}>{location.addressStreet1}</p>
                {location.addressStreet2 && <p style={{ margin: 0, fontSize: 14, marginBottom: 5 }}>{location.addressStreet2}</p>}
                <p style={{ margin: 0, fontSize: 14, marginBottom: 10 }}>
                  {location.addressCity}, {location.addressState} {location.addressZipcode}
                </p>
                <p style={{ margin: 0, fontSize: 14, color: colors.green, marginBottom: 15 }}>{isLocationOpen(location)}</p>
                <IonRouterLink routerLink={`/haircuts/?id=${location._id}`} routerDirection='none'>
                  <div
                    style={{
                      fontFamily: 'Montserrat, Arial',
                      padding: '8px 12px',
                      fontWeight: '600',
                      fontSize: 14,
                      color: 'black',
                      backgroundColor: colors.gold,
                      border: `1px solid ${colors.gold}`,
                      borderRadius: 6,
                      textAlign: 'center',
                    }}
                  >
                    View Location
                  </div>
                </IonRouterLink>
              </div>
            </IonCard>
          ))}
        </div>
      </StandardContainer>
    </div>
  );
}

function Footer() {
  return (
    <div style={{ backgroundColor: 'black' }}>
      <StandardContainer>
        <div style={{ width: '100%', height: 1, backgroundColor: '#ffffff55' }} />
        <br></br>
        <br></br>
        <StandardCenterCenter>

          <a href='/https://clubcuts.com/terms-and-conditions' type='page' style={{ textDecoration: 'none', color: colors.green }}>
            Terms &amp; Conditions
          </a>
          <br></br>
          <a href='/schedule' type='page' style={{ textDecoration: 'none', color: colors.green }}>
            Book an Appointment
          </a>
          <br></br>
          <a href='/subscription' type='page' style={{ textDecoration: 'none', color: colors.green }}>
            Subscriptions
          </a>
        </StandardCenterCenter>
        <div style={{ marginTop: 20 }}>
          <StandardCenterCenter>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <a href='mailto:hello@clubcuts.com'>
                    <IonIcon icon={mailOpenOutline} style={{ color: 'white', fontSize: 30 }} />
                  </a>
                </IonCol>
                <IonCol>
                  <a href='http://instagram.com/clubcutsbarbershop'>
                    <IonIcon icon={logoInstagram} style={{ color: 'white', fontSize: 30 }} />
                  </a>
                </IonCol>
                <IonCol>
                  <a href='https://facebook.com/clubcuts'>
                    <IonIcon icon={logoFacebook} style={{ color: 'white', fontSize: 30 }} />
                  </a>
                </IonCol>
                <IonCol>
                  <a href='https://www.google.com/maps/place/ClubCuts+Barbershop/@39.5647451,-104.9620738,15z/data=!4m6!3m5!1s0x876c85670b6e7d59:0x9cada40d15046002!8m2!3d39.5647451!4d-104.9620738!16s%2Fg%2F11t31f4nts'>
                    <IonIcon icon={storefrontOutline} style={{ color: 'white', fontSize: 30 }} />
                  </a>
                </IonCol>
              </IonRow>
            </IonGrid>
          </StandardCenterCenter>
        </div>
      </StandardContainer>
    </div>
  )
}

export default AdsLandingPage
